import * as React from 'react';
import Feedback from 'react-bootstrap/Feedback';
import RBForm, { FormProps } from 'react-bootstrap/Form';
import styled, { css } from 'styled-components';

export const focusFormStyles = css`
  background-color: ${({ theme }) => theme.color.background800};
  border-color: ${({ theme }) => theme.color.emphasisColor};
  box-shadow: none;
  color: ${({ theme }) => theme.color.color};
`;

/**
 * Styles applied to form elements like `select`, `input`, and `textarea`.
 */
export const formStyles = css<{ size?: 'sm' | 'lg' }>`
  background-color: ${({ theme }) => theme.color.background800};
  border: 1px solid ${({ theme }) => theme.color.secondaryBorder};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.color.color};
  transition: border-color 200ms linear, color 200ms linear;

  ${({ size, theme }) => {
    if (size === 'sm') {
      return `
        font-size: ${theme.fontSize.xSmall};
        height: 28px;
        padding: 7px;
      `;
    }

    if (size === 'lg') {
      return `
        font-size: ${theme.fontSize.medium};
        height: 48px;
        padding: 12px;
      `;
    }

    return `
      font-size: ${theme.fontSize.normal};
      height: 40px;
      padding 10px;
    `;
  }}

  &:focus,
  &:active {
    ${focusFormStyles}
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.background400};
    border-color: ${({ theme }) => theme.color.secondaryBorder};
  }

  &:hover:not(:focus, :disabled) {
    border-color: ${({ theme }) => theme.color.tertiaryBorder};
  }

  &.is-valid {
    border-color: ${({ theme }) => theme.color.variant.success.border};
    color: ${({ theme }) => theme.color.variant.success.text};
  }

  &.is-invalid {
    border-color: ${({ theme }) => theme.color.variant.danger.border};
    color: ${({ theme }) => theme.color.variant.danger.text};
  }

  &.is-valid,
  &.is-invalid {
    /* Don't show default BS validation icons. */
    background-image: none;
    padding-right: unset;

    &:focus {
      box-shadow: none;
    }
  }
`;

const _FormGroup = styled(RBForm.Group)`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const _FormCheck = styled(RBForm.Check)`
  .form-check-input {
    background-color: ${({ theme }) => theme.color.background300};
    border-color: ${({ theme }) => theme.color.background300};

    &:focus,
    &:hover {
      border-color: ${({ theme }) => theme.color.secondaryEmphasisColor};
      box-shadow: unset;
    }
  }

  .form-check-input:checked {
    background-color: ${({ theme }) => theme.color.secondaryEmphasisColor};
    border-color: ${({ theme }) => theme.color.secondaryEmphasisColor};
  }
`;

export type IFormProps = Omit<FormProps, 'as'>;

function Form(props: IFormProps) {
  return <RBForm {...props} />;
}

/**
 * The following components are intentionally omitted for now:
 *
 *    - FormFloating
 *    - FormRange
 *    - FormSelect
 */

Form.Check = _FormCheck;
Form.Control = RBForm.Control;
Form.Feedback = Feedback;
Form.Group = _FormGroup;
Form.Label = RBForm.Label;
Form.Text = RBForm.Text;

export default Form;
