import { RTElementProp, RTLinkCardElement } from '@rmvw/x-common';
import * as React from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';
import styled from 'styled-components';

import { IS_MOBILE_APP } from '../../../../env';
import Card from '../../../Card';
import CloseButton from '../../../CloseButton';
import ExternalMediaPlayer from '../../../ExternalMediaPlayer';
import LinkPreviewWithQuery from '../../../LinkPreview';
import { IRTElementProps } from '../../IRTElementProps';
import VoidInlineElement from '../common/VoidInlineElement';
import DefaultElement from '../DefaultElement/DefaultElement';

export default function LinkCardElement(props: IRTElementProps<RTLinkCardElement>) {
  const editor = useSlateStatic();
  const selected = useSelected();

  try {
    const href = props.element[RTElementProp.LINK__HREF];
    if (!href) {
      return props.children;
    }

    const closeButton = props.editable && (
      <_CloseButtonContainer>
        <CloseButton
          onClick={() => {
            const path = ReactEditor.findPath(editor as ReactEditor, props.element);
            Transforms.removeNodes(editor, { at: path });
          }}
          size="small"
        />
      </_CloseButtonContainer>
    );

    const isYoutube = href.includes('youtube.com') || href.includes('youtu.be');

    return (
      <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
        {isYoutube && !IS_MOBILE_APP ? (
          <ExternalMediaPlayer url={href} />
        ) : (
          <_Container $editable={props.editable} selected={selected}>
            {closeButton}
            <Card.Body>
              <LinkPreviewWithQuery url={href} />
            </Card.Body>
          </_Container>
        )}
      </VoidInlineElement>
    );
  } catch (e) {
    // Not a valid URL...do not linkify
    return <DefaultElement {...props} />;
  }
}

const _CloseButtonContainer = styled.div`
  opacity: 0;
  position: absolute;
  right: 8px;
  top: 8px;
  transition: opacity 0.25s linear;
`;

const _Container = styled(Card)<{ $editable?: boolean }>`
  ${({ $editable }) => $editable && 'padding-right: 36px;'}
  position: relative;

  &:hover ${_CloseButtonContainer} {
    opacity: 1;
  }

  ${(props) => {
    if (props.selected) {
      return `
        outline: 2px solid ${props.theme.color.variant.primary.color};
        outline-offset: -2px;
      `;
    }
  }};
`;
