/* eslint-disable no-restricted-imports */

import { gql, useQuery } from '@apollo/client';
import { RTElementProp, RTImageElement } from '@rmvw/x-common';
import { Image } from 'antd';
import * as React from 'react';
import { RenderElementProps, useSelected } from 'slate-react';
import styled from 'styled-components';

import { useDownloadImage } from '../../../../hooks/useDownloadImage';
import { hexColorWithAlpha } from '../../../../lib/css';
import { DownloadIcon, RotateLeftIcon, RotateRightIcon, ZoomInIcon, ZoomOutIcon } from '../../../Icon';
import Tooltip from '../../../Tooltip';
import Button from '../../../ui/Button';
import Space from '../../../ui/Space';
import { IRTElementProps } from '../../IRTElementProps';
import ElementPlaceholder from '../common/ElementPlaceholder';
import ResolverStatusOverlay from '../common/ResolverStatusOverlay';
import { IUseResolverStatusResult, useResolverStatus } from '../common/useResolverStatus';
import VoidInlineElement from '../common/VoidInlineElement';

import {
  BATCHED__ImageElement_ImageQuery,
  BATCHED__ImageElement_ImageQueryVariables,
} from './___generated___/ImageElement.types';

const MAX_PREVIEW_HEIGHT_PX = 135;
const MAX_PREVIEW_WIDTH_PX = 240;

const fragment = gql`
  fragment CF_ImageElement on Image {
    id
    previewUrl: url_512
    galleryUrl: url_4096
  }
`;

const ImageQuery = gql`
  ${fragment}
  # Batched query
  query BATCHED__ImageElement_ImageQuery($imageId: ID!) {
    image(id: $imageId) {
      ...CF_ImageElement
    }
  }
`;

const _Container = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.medium};
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
`;

const _InnerContainer = styled.div`
  position: relative;
`;

const _SelectedOverlay = styled.div`
  background-color: ${({ theme }) => hexColorWithAlpha(theme.color.textSelectionBackground, 0.5)};
  inset: 0;
  position: absolute;
`;

const _Image = styled(Image)<{ $height?: number; $width?: number }>`
  height: ${({ $height, $width }) =>
    $height && $height < MAX_PREVIEW_HEIGHT_PX && (!$width || $width < MAX_PREVIEW_WIDTH_PX) ? `${$height}px` : 'auto'};
  max-height: ${MAX_PREVIEW_HEIGHT_PX}px;
  max-width: ${MAX_PREVIEW_WIDTH_PX}px;
  object-fit: ${({ $height, $width }) =>
    $height && $height < MAX_PREVIEW_HEIGHT_PX && $width && $width < MAX_PREVIEW_WIDTH_PX ? 'cover' : 'contain'};
  width: ${({ $height, $width }) =>
    $width && $width < MAX_PREVIEW_WIDTH_PX && (!$height || $height < MAX_PREVIEW_HEIGHT_PX) ? `${$width}px` : 'auto'};
`;

const _ImagePlaceholder = styled(ElementPlaceholder)<{ $height?: number; $width?: number }>`
  border-radius: 0;
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
`;

const _ImagePreviewButton = styled(Button).attrs({ bordered: true, shape: 'circle' })``;

export interface IBaseImageElementProps extends Omit<RenderElementProps, 'element'> {
  editable?: boolean;
  gallerySrc?: string;
  height?: number;
  imageId?: string;
  previewSrc?: string;
  resolverStatus?: IUseResolverStatusResult;
  selected?: boolean;
  width?: number;
}

export function BaseImageElement({
  attributes,
  children,
  editable,
  gallerySrc,
  height,
  imageId,
  previewSrc,
  resolverStatus,
  selected,
  width,
}: IBaseImageElementProps) {
  const downloadImage = useDownloadImage({ id: imageId });
  return (
    <VoidInlineElement attributes={attributes} slateChildren={children}>
      <_Container>
        <_InnerContainer>
          {previewSrc ? (
            // TODO: migrate off antd Image / PreviewImage
            <_Image
              $height={height}
              height={
                height && height < MAX_PREVIEW_HEIGHT_PX && (!width || width < MAX_PREVIEW_WIDTH_PX)
                  ? `${height}px`
                  : 'auto'
              }
              $width={width}
              width={
                width && width < MAX_PREVIEW_WIDTH_PX && (!height || height < MAX_PREVIEW_HEIGHT_PX)
                  ? `${width}px`
                  : 'auto'
              }
              onClick={async (e) => {
                if (editable) {
                  return;
                }

                if (e.altKey) {
                  e.preventDefault();
                  e.stopPropagation();

                  // If user is holding alt/option key, download the image
                  await downloadImage();
                  return;
                }
              }}
              preview={
                !editable && gallerySrc
                  ? {
                      maskStyle: { backgroundColor: 'black', webkitAppRegion: 'no-drag' },
                      src: gallerySrc,
                      toolbarRender: (
                        _,
                        { transform: { scale }, actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <Tooltip title="Download">
                            <_ImagePreviewButton icon={<DownloadIcon />} onClick={downloadImage} />
                          </Tooltip>
                          <Tooltip title="Zoom Out">
                            <_ImagePreviewButton disabled={scale === 1} icon={<ZoomOutIcon />} onClick={onZoomOut} />
                          </Tooltip>
                          <Tooltip title="Zoom In">
                            <_ImagePreviewButton disabled={scale === 50} icon={<ZoomInIcon />} onClick={onZoomIn} />
                          </Tooltip>
                          <Tooltip title="Rotate Left">
                            <_ImagePreviewButton icon={<RotateLeftIcon />} onClick={onRotateLeft} />
                          </Tooltip>
                          <Tooltip title="Rotate Right">
                            <_ImagePreviewButton icon={<RotateRightIcon />} onClick={onRotateRight} />
                          </Tooltip>
                        </Space>
                      ),
                    }
                  : false
              }
              src={previewSrc}
            />
          ) : (
            <_ImagePlaceholder $height={height ?? MAX_PREVIEW_HEIGHT_PX} $width={width ?? MAX_PREVIEW_HEIGHT_PX} />
          )}
          {editable && resolverStatus && <ResolverStatusOverlay status={resolverStatus.status} />}
          {selected && <_SelectedOverlay />}
        </_InnerContainer>
      </_Container>
    </VoidInlineElement>
  );
}

const DEFAULT_IMAGE_QUERY_POLL_INTERVAL_MS = 15_000;

export default function ImageElement(props: IRTElementProps<RTImageElement>) {
  const selected = useSelected();
  const resolverStatus = useResolverStatus(props);
  const imageQuery = useQuery<BATCHED__ImageElement_ImageQuery, BATCHED__ImageElement_ImageQueryVariables>(ImageQuery, {
    // Temporary workaround: poll interval compensates for limitations in the current pubsub model.
    // Will be replaced by a more robust, object-level pubsub subscription & routing system in the future.
    pollInterval: DEFAULT_IMAGE_QUERY_POLL_INTERVAL_MS,
    skip: !props.element[RTElementProp.IMAGE__IMAGE_ID],
    variables: { imageId: props.element[RTElementProp.IMAGE__IMAGE_ID] ?? '' },
  });

  // Stop polling when gallery url is ready
  React.useEffect(() => {
    if (imageQuery.data?.image?.galleryUrl) {
      imageQuery.stopPolling();
    }
  }, [imageQuery]);

  const gallerySrc = imageQuery.data?.image?.galleryUrl ?? undefined;
  const height = props.element[RTElementProp.IMAGE__HEIGHT] ? +props.element[RTElementProp.IMAGE__HEIGHT] : undefined;
  const previewSrc = resolverStatus.previewUrl ?? imageQuery.data?.image?.previewUrl ?? undefined;
  const width = props.element[RTElementProp.IMAGE__WIDTH] ? +props.element[RTElementProp.IMAGE__WIDTH] : undefined;

  return (
    <BaseImageElement
      {...props}
      gallerySrc={gallerySrc}
      height={height}
      imageId={imageQuery.data?.image?.id}
      previewSrc={previewSrc}
      resolverStatus={resolverStatus}
      selected={selected}
      width={width}
    />
  );
}

ImageElement.fragment = fragment;
