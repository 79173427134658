import { LegacyPalette } from '@rmvw/c-common';
import styled from 'styled-components';

import Card from '../Card';

const MediaContainer = styled(Card)`
  align-items: center;
  aspect-ratio: 16 / 9;
  background-color: ${LegacyPalette.black};
  border: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: unset;
  }
`;

export default MediaContainer;
