import { RTClientSourcedImageElement, RTElementProp } from '@rmvw/x-common';
import * as React from 'react';
import { RenderElementProps, useSelected } from 'slate-react';
import styled from 'styled-components';

import { hexColorWithAlpha } from '../../../../lib/css';
import { IRTElementProps } from '../../IRTElementProps';
import VoidInlineElement from '../common/VoidInlineElement';

export interface IClientSourcedImageElementProps extends Omit<RenderElementProps, 'element'> {
  src: string;
}

function ClientSourcedImageElement(props: IRTElementProps<RTClientSourcedImageElement>) {
  const selected = useSelected();
  const imgSrc = props.element[RTElementProp.CLIENT_SOURCED_IMAGE__URL];
  const altText = props.children?.props?.children?.props?.text;

  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <_Container>
        <_InnerContainer>
          <img src={imgSrc} alt={altText} />
          {selected ? <_SelectedOverlay /> : null}
        </_InnerContainer>
      </_Container>
    </VoidInlineElement>
  );
}

export default ClientSourcedImageElement;

const _SelectedOverlay = styled.div`
  background-color: ${({ theme }) => hexColorWithAlpha(theme.color.textSelectionBackground, 0.5)};
  inset: 0;
  position: absolute;
`;

const _Container = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.medium};
  display: inline-block;
  max-width: ${({ theme }) => theme.dimension.threadPreviewCard.maxWidth};
  overflow: hidden;
  vertical-align: bottom;
`;

const _InnerContainer = styled.div`
  position: relative;
`;
