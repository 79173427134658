import * as React from 'react';
import styled from 'styled-components';

import MediaPlayer from '../MediaPlayer';

const _PlayerContainer = styled.div`
  aspect-ratio: 16 / 9;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  height: 100%;
  max-height: ${({ theme }) => theme.dimension.threadPreviewCard.maxHeight};
  max-width: ${({ theme }) => theme.dimension.threadPreviewCard.maxWidth};
  overflow: hidden;
  width: 100%;
`;

export interface IExternalMediaPlayerProps {
  url: string;
}

function ExternalMediaPlayer({ url }: IExternalMediaPlayerProps) {
  return (
    <_PlayerContainer>
      <MediaPlayer
        controls
        config={{
          youtube: {
            playerVars: {
              enablejsapi: 1,
              playsinline: 1, // prevent default fullscreen playback on mobile
            },
          },
        }}
        height={'100%'}
        playsinline // on mobile, prevent default fullscreen playback
        url={url}
        width={'100%'}
      />
    </_PlayerContainer>
  );
}

export default ExternalMediaPlayer;
