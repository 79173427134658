import { gql, useQuery } from '@apollo/client';

import { HQ_useLoggedInAccount } from './___generated___/useLoggedInAccount.types';

export const useLoggedInAccountQuery = gql`
  query HQ_useLoggedInAccount {
    account {
      id
      activityStatus
      emojiSkinTone
      givenName
      isInternal
      isMe
      isNew
      name
      locale
      org {
        id
        name
      }
      teamMemberships {
        id
      }
    }
  }
`;

export function useLoggedInAccount() {
  const { data } = useQuery<HQ_useLoggedInAccount>(useLoggedInAccountQuery);
  return data?.account || null;
}
