import * as React from 'react';
import styled, { css } from 'styled-components';

import { truncateText } from '../../lib/css';

const paddingStyles = css`
  padding: 16px;
`;

const _Card = styled.div<{ $selected?: boolean }>`
  background-color: ${({ theme }) => theme.color.background800};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.color.color};
  display: block;
  position: relative;
  transition: border-color 0.5s;

  ${({ $selected, theme }) => {
    if ($selected) {
      return `
        outline: 2px solid ${theme.color.variant.primary.color};
        outline-offset: -2px;
      `;
    }
  }};

  &:hover {
    border-color: ${({ theme }) => theme.color.secondaryBorder};
    color: ${({ theme }) => theme.color.color};
  }
`;

const _Header = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin: 0 16px;
  padding: 16px 0;
`;

const _Title = styled.h6`
  color: ${({ theme }) => theme.color.color};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0;
  ${truncateText()}
`;

const _Body = styled.div`
  display: flex;
  flex-direction: column;
  ${paddingStyles}
`;

const _Footer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  ${paddingStyles}
  padding-top: 4px;
`;

export interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  href?: string;
  target?: string;
  selected?: boolean;
}

const Card = React.forwardRef<HTMLDivElement, ICardProps>(
  ({ href, onClick, selected, target, ...divProps }: ICardProps, ref) => {
    let interactiveProps = {};

    if (onClick && href) {
      throw Error('Card: Use either `href` or `onClick`');
    }

    // Link
    if (href) {
      interactiveProps = {
        as: 'a',
        href,
      };
    }

    // Button
    if (onClick) {
      interactiveProps = {
        as: 'a',
        href: '#',
        onClick: (e: React.MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          onClick?.(e);
        },
        role: 'button',
      };
    }

    return <_Card {...divProps} {...interactiveProps} $selected={selected} ref={ref} />;
  }
);

Card.displayName = 'Card';

export default Object.assign(Card, { Header: _Header, Title: _Title, Body: _Body, Footer: _Footer });
